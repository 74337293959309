import React from 'react'
import About from '../Components/about'
import Areas from '../Components/areas'
import Footer from '../Components/footer'
import Forms from '../Components/forms'
import Header from '../Components/header'
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Atendimento from '../Components/atendimento'

function MainPage() {
  return (
    <div>
      <Header />
      {/* <Mission /> */}
      <AnimationOnScroll animateIn='animate__fadeIn' duration={1}>
        <About />
      </AnimationOnScroll>
      <Areas />
      <Forms />
      <Footer />
      <Atendimento />
    </div>
  )
}

export default MainPage