import React from 'react'
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './mission.css'

function Mission() {
  return (
    <div>
        <AnimationOnScroll animateIn='animate__fadeIn' duration={1} className='mission-container'>
        {/* <img src="https://img.icons8.com/ios-filled/100/null/torah.png" alt="lema"/> */}
        <h2>Valores</h2>
        <span>Advocacia focada no compromisso com o cliente, na verdade, no respeito e empatia com o seu momento. Soluções eficientes e personalizadas para cada cliente nas áreas civel, tributária e digital.</span>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn='animate__fadeIn' duration={1} className='mission-container'>
        {/* <img src='https://img.icons8.com/ios-filled/100/null/goal--v1.png' alt='objetivo'/> */}
        <h2>Missão</h2>
        <span>A nossa missão é resolver demandas de forma prática e assertiva, trazendo os benefícios do direito para a vida do cliente.</span>
        </AnimationOnScroll>
    </div>
  )
}

export default Mission