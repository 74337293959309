import React from 'react'
import './footer.css'

function Footer() {
  const openZap = () => {
    window.open('https://api.whatsapp.com/send?phone=5531991978392', "_blank")
  }

  const openInsta = () => {
    window.open('https://www.instagram.com/denise_lacerda_adv', "_blank")
  }

  const openEmail = () => {
    window.open('mailto:delacerdaadvocacia%40gmail.com?subject=')
  }
  return (
    <>
    <div id="contact" className='contact-container'>     
    {/* <div className='footer-image'>
        <img src='/Images/logo-footer.jpg' alt='logo rodapé'/>
      </div>             */}
        <div className='contact'>
        <h3 id='input-name'>Denise Cristina Pinheiro de Lacerda</h3>
        <span id='span-oab'>Advogada OAB/MG nº 220.456</span>
        <div className='contact-links'>  
          <span id='input-zap'><input type='image' src='https://img.icons8.com/ios-filled/1e2c51/null/whatsapp--v1.png' alt='iconezap' onClick={ () => openZap()}/> +55 31 99197-8392</span>
          <span id='input-email'><input type='image' src='https://img.icons8.com/ios-filled/1e2c51/null/email.png' alt='iconelinkedin' onClick={() => openEmail()}/>delacerdaadvocacia@gmail.com</span>
          <span id='input-insta'><input type='image' src='https://img.icons8.com/ios-filled/1e2c51/null/instagram-new--v1.png' alt='iconeinstagram' onClick={() => openInsta()}/>@denise_lacerda_adv</span>
        </div> 
      </div>
      {/* <div className='icon8'>
      <p>Icons By icons8:</p>
          <a href='#contact' >Icone Email</a>
          <a href='https://icons8.com/icon/xdH5TjdkGNDf/cabeça-humana' target='_blank' rel="noreferrer">Icone Cabeça</a>
          <a href='https://icons8.com/icon/Rdp3AydLFY2A/cardápio' target='_blank' rel="noreferrer">Icone Cardápio</a>
          <a  href="https://icons8.com/icon/1wfighAmY2Oy/contract" target="_blank" rel="noreferrer">Icone Contrato</a>
          <a target="_blank" href="https://icons8.com/icon/zq7OkVHocxK5/defend-family" rel="noreferrer">Icone Família</a>
          <a target="_blank" href="https://icons8.com/icon/1846/caixa-cheia" rel="noreferrer"> Icone Caixa</a>
          <a target="_blank" href="https://icons8.com/icon/iUE5T0Zy8miY/laptop-gdpr" rel="noreferrer">Icone Laptop GDPR</a>
          <a target="_blank" href="https://icons8.com/icon/25231/livro-de-direito" rel="noreferrer">Icone Direito</a>
          <a target="_blank" href="https://icons8.com/icon/11691/atenção" rel="noreferrer">Icone Atenção</a>
          <a target="_blank" href="https://icons8.com/icon/105372/crescimento-financeiro-fraco" rel="noreferrer">Icone Financeiro</a>
          <a target="_blank" href="https://img.icons8.com/ios/50/null/email.png" rel="noreferrer">Icone Instagram</a>
          <a target="_blank" href="https://icons8.com/icon/1669/e-mail" rel="noreferrer">Icone Email</a>
      </div>  */}
    </div>
    </>
  )
}

export default Footer
