import React, { useState } from 'react'
import './header.css'

export default function Header() {
    const [menu, setMenu] = useState(false)
    // const [time, setTime] = useState(true)
    const x = window.matchMedia("(max-width: 480px)")

    const openMenu = () => {
      if (menu) {
        setMenu(false)
      } else {
        setMenu(true)
      }
    }

    const openZap = () => {
      window.open('https://api.whatsapp.com/send?phone=5531991978392', "_blank")
    }

    const openInsta = () => {
      window.open('https://www.instagram.com/denise_lacerda_adv', "_blank")
    }

    const openEmail = () => {
      window.open('mailto:delacerdaadvocacia%40gmail.com?subject=')
    } 

    const menuO = 'https://img.icons8.com/ios-filled/50/c99e67/menu-rounded.png'
    const menuX = 'https://img.icons8.com/ios-filled/50/c99e67/multiply.png'

  return (
    <div className='header-container' id="top">
      <div className='header'>
        {
          x.matches ? (
          <input type="image" src={menu ? menuX : menuO} alt='menu formato hamburguer' onClick={() => openMenu()}/>
          ) :
          (
          <ul id='list-container'>
            <img src='/Images/logo-header.png' alt='logo cabeçalho' id='logo-header'/>
            <li><a href='#top' >HOME</a></li>
            <li><a href='#about' >SOBRE</a></li>
            <li><a href='#areas' >ÁREAS DE ATUAÇÃO</a></li>
            <li><a href='#contact' >CONTATOS</a></li>
          </ul>
          )
        }
        {
          menu && (
              <div id='list-container-mobile'>
                <a href='#top' >HOME</a>
                <a href='#about' >SOBRE</a>
                <a href='#areas' >ÁREAS DE ATUAÇÃO</a>
                <a href='#contact' >CONTATOS</a>
              </div>
          ) 
        }
      </div>
      <div className='text-container'>
          <div className='newText-container'>
                <img id='logo' src='/Images/logo-denise1.png' alt='logo denise advocacia'/>
                <h2>Especialista em Direito Civil, Direito de Família e das Sucessões</h2>
          </div>
        <div className='icons'>
              <input type='image' src='https://img.icons8.com/ios/50/c99e67/whatsapp--v1.png' alt='iconezap' onClick={ () => openZap()} />
              {/* <input type='image' src='https://img.icons8.com/ios/50/339AF0/facebook-new.png' alt='iconefacebook'/>  */}
              <input type='image' src='https://img.icons8.com/ios/50/c99e67/instagram-new--v1.png' alt='iconeinstagram' onClick={ () => openInsta()}/>
              <input type='image' src='https://img.icons8.com/ios-filled/50/c99e67/email.png' alt='iconelinkedin' onClick={() => openEmail()}/>         
        </div>
      </div>
    </div>
  )
}
