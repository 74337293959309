import MainPage from './Pages';
import './App.css';

function App() {
  return (
    <MainPage />
  );
}

export default App;
