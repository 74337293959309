import React from 'react'
import './about.css'

function About() {
  return (
    <div className='about-container' id="about">
        <div className='description-container'>
            <h2>Dra. DENISE CRISTINA PINHEIRO DE LACERDA</h2>
            <p id='text'>Formada em Direito pela Pontifícia Universidade Católica de Minas Gerais. É Especialista em Direito Civil, Direito de Família e Direito de Sucessões. Atua profissionalmente na jurisdição contenciosa, na realização de consultoria jurídica e na mediação judicial e extrajudicial.</p>
            {/* <p id='text'>Advocacia focada no compromisso com o cliente, na verdade, no respeito e empatia com o seu momento. Soluções eficientes e personalizadas para cada cliente nas áreas cívil, tributária e direito digital</p> */}
        </div>
        <div className='img-container'>
            <img src='/Images/denise-col.jpg' alt='teste'/>
        </div>
    </div>
  )
}

export default About
