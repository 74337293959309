import React from 'react'
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import './forms.css'
import 'react-toastify/dist/ReactToastify.css'

function Forms() {
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [message, setMessage] = React.useState('')

    const notifyError= (error) => {
      toast.error(`${error}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }

    const notifySuccess = (success) => {
      toast.success(`${success}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

    const handleSubmit = () => {
        
        if(name === '' || email === '' || message === '') {
          notifyError('Por Favor, preencha todos os campos!')
        }

        const regex = /\S+@\S+\.\S+/;
        if(regex.test(email) === false && email !== '') {
            notifyError('Por Favor, insira um email válido!')
        }

        const templateParams = {
          from_name: name,
          message: message,
          email: email
        }

        if(name.length > 0 && email.length > 0 && message.length > 0 && regex.test(email) === true) {
          emailjs.send('service_rm60fa9','template_2twv5bb',templateParams,'RswBVImuVzJ5DT73x')
          .then((result) => {
            setName('')
            setEmail('')
            setMessage('')
            notifySuccess('Mensagem enviada com sucesso!')
          }, () => {
            notifyError('Erro ao enviar a mensagem!')
          })
        }
    }


  return (
    <div class="form-container">
        <form class="form">
            <div class="title">Fale Conosco</div>
            <div class="input-container ic1">
            <input id="firstname" class="input" type="text" placeholder=" " value={name} onChange={(e) => setName(e.target.value)} required/>
            <div class="cut"></div>
            <label for="firstname" class="placeholder">Nome</label>
            </div>
            <div class="input-container ic2">
            <input id="lastname" class="input" type="email" placeholder=" "  value={email} onChange={(e) => setEmail(e.target.value)} required/>
            <div class="cut"></div>
            <label for="lastname" class="placeholder">Email</label>
            </div>
            <div class="input-container ic2 textarea">
            <textarea id="email" class="input" type="text" placeholder=" " value={message} onChange={(e) => setMessage(e.target.value)}/>
            <div class="cut cut-short"></div>
            <label for="email" class="placeholder">Escreva sua mensagem</label>
            </div>
            <button type="button" class="submit" onClick={handleSubmit}>Enviar</button>
        </form>
  </div>
  )
}

export default Forms
