/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import './atendimento.css'

function Atendimento() {
  const [paracatu, setParacatu] = React.useState(false)
  const [guaja, setGuaja] = React.useState(false)
  const [pena, setPena] = React.useState(false)

  const setaCima = "https://img.icons8.com/ios-filled/50/null/chevron-up.png"
  const setaBaixo = "https://img.icons8.com/ios-filled/50/null/chevron-down.png"
  return (
    <>
    <div className='atendimento-container'>
        <h3 id='text-agenda'>Atendimento a distância via reunião on-line e presencial nos endereços a ser agendados previamente:</h3>
        <div className='lista-ruas'>
            <span>Rua Paracatu, 472, Barro Preto, Belo Horizonte - MG</span>
            <input type='image' src={paracatu ? setaCima : setaBaixo } alt="seta para baixo" id='paracatu' name='paracatu' value='VER MAPA' onClick={() => setParacatu(!paracatu)} />
            {
              paracatu && (
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3751.0562810799743!2d-43.95618891936849!3d-19.922033006749395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa6975cea20687f%3A0xe2a2f32ba25b4357!2sR.%20Paracatu%2C%20472%20-%20Barro%20Preto%2C%20Belo%20Horizonte%20-%20MG%2C%2030180-090!5e0!3m2!1spt-BR!2sbr!4v1668440860959!5m2!1spt-BR!2sbr"></iframe>  
              )
            }
            <span>Rua dos Guajajaras, 40/ 11° andar, Centro, Belo Horizonte - MG</span>
            <input type='image' src={guaja ? setaCima : setaBaixo } alt="seta para baixo" id='guaja' name='guaja' value='VER MAPA' onClick={() => setGuaja(!guaja)} />
            {
              guaja && (
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.951448216243!2d-43.93873521936789!3d-19.926450706881155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa699e604b1eb01%3A0xb976072cf8c7b48e!2sRua%20dos%20Guajajaras%2C%2040%20-%20Centro%2C%20Belo%20Horizonte%20-%20MG%2C%2030180-100!5e0!3m2!1spt-BR!2sbr!4v1668442075313!5m2!1spt-BR!2sbr"></iframe> )
            }
            <span>Av. Afonso Pena, 4028, Centro, Belo Horizonte - MG</span>
            <input type='image' src={pena ? setaCima : setaBaixo } alt="seta para baixo" id='pena' name='pena' value='VER MAPA' onClick={() => setPena(!pena)} />
            {
              pena && (
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.5011842012705!2d-43.92465695006369!3d-19.94541434387244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa699b7a66184b7%3A0xaa69cf5338711fc0!2sAv.%20Afonso%20Pena%2C%204028%20-%20Centro%2C%20Belo%20Horizonte%20-%20MG%2C%2030130-009!5e0!3m2!1spt-BR!2sbr!4v1668442141169!5m2!1spt-BR!2sbr"></iframe> )
            }
        </div>
    </div>
            <p id='copyright'>2022 © Denise Cristina Pinheiro de Lacerda - Todos os direitos reservados</p>
            </>
  )
}

export default Atendimento