import React from 'react'
import './areas.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Mission from './mission';

function Areas() {
    const [contrato, setContrato] = React.useState(false)
    const [familia, setFamilia] = React.useState(false)
    const [inventário, setInventário] = React.useState(false)
    const [sucessão, setSucessão] = React.useState(false)
    const [preventina, setPreventina] = React.useState(false)
    const [dados, setDados] = React.useState(false)

  return (
    <div  className='container-primario' id="areas">
        <h1 id='title-area'>Áreas de atuação</h1>
        <div className='direitos-cotainer'>
            <a href='#civil'>Direito Civil</a>
            <a href='#digital'>Direito de Família</a>
            <a href='#tributário'>Direito de Sucessões</a>
        </div>
        <Mission />
    <Swiper
    modules={[Navigation, Pagination, Autoplay ]}
    spaceBetween={50}
    slidesPerView={1}
    navigation
  >
    <SwiperSlide id="civil">
        <h2>Direito Civil</h2>
            <div className='atuações-container'>
            {
                contrato ? (
                <div className='atuações-explicações' onClick={() => setContrato(false)}>
                    <p>Elaboração, interpretação e ação judicial, para o cumprimento de uma obrigação</p>
                    {/* <h4>Atuação Preventiva</h4>
                    <h4>Atuação na interpretação do contrato</h4>
                    <h4>Atuação após a formalização do contrato</h4>
                    <h4>Pacto antenupcial </h4> */}
                </div>
                ) : (
                <div className='atuações-cards' onClick={() => setContrato(true)}>
                    {/* <img src='https://img.icons8.com/external-outline-juicy-fish/60/null/external-contract-banking-outline-outline-juicy-fish.png' alt='Icone Contrato'/> */}
                    <h4>Contratos</h4>
                    <p>Clique no card para saber mais</p>
                </div>
                )
            }
            {
                // familia ? (
                // <div className='atuações-explicações' onClick={() => setFamilia(false)}>
                //     {/* <p><em>Família possui normas que trabalham de acordo com orientação constitucional do conceito de família, levando em conta o entendimento jurisprudencial, em âmbito jurídico, e transformações sociais.</em></p> */}
                //     <h4>Ação de divórcio</h4>
                //     <h4>Ação e execução de alimentos</h4>
                //     <h4>Ações de paternidade</h4>
                //     {/* <h4>Ação negatória de paternidade</h4>
                //     <h4>Ação de Investigação de Paternidade</h4> */}
                //     <h4>Alteração do regime de bens</h4>
                //     {/* <h4>Execução da obrigação alimentar</h4> */}
                //     <h4>Reconhecimento e extinção de união estável</h4>
                //     <h4>Guarda, visitação e filiação</h4>
                // </div>
                // ) : (
                // <div className='atuações-cards' onClick={() => setFamilia(true)}>
                //     {/* <img src='https://img.icons8.com/ios/50/null/defend-family--v2.png' alt='Icone Família'/> */}
                //     <h4>Direito de Família</h4>
                //     <p>Clique no card para saber mais</p>
                // </div>
                // )
            }
            {
                inventário ? (
                <div className='atuações-explicações' onClick={() => setInventário(false)}>
                    {/* <p><em>O inventário é o procedimento pelo qual se define quais bens integram o a cervo herditário e qual quinhão pertencerá a cada herdeiro.</em></p> */}
                    <h4>Judicial e/ou Extrajudicial</h4>
                    {/* <h4>Ação de execução de título extrajudicial</h4> */}
                </div>
                ) : (
                <div className='atuações-cards' onClick={() => setInventário(true)}>
                    {/* <img src='https://img.icons8.com/ios/50/null/open-box.png' alt='Icone Acordo'/> */}
                    <h4>Cobrança</h4>
                    <p>Clique no card para saber mais</p>
                </div>
                )
            }
            {
                // sucessão ? (
                // <div className='atuações-explicações' onClick={() => setSucessão(false)}>
                //     {/* <p><em>O Direito das Sucessões é uma área do direito que regula o processo de transferência do patrimônio de uma pessoa falecida aos seus herdeiros.</em></p> */}
                //     {/* <h4>Falecimento</h4>
                //     <h4>indivisibilidade</h4>
                //     <h4>Inventário negativo</h4>
                //     <h4>Inventário administrativo/extrajudicial</h4>
                //     <h4>Inventário judicial pelo rito, sumário ou comum</h4>
                //     <h4>Inventário judicial pelo procedimento tradicional</h4> */}
                //     <h4>Inventário judicial e/ou extrajudicial</h4>
                // </div>    
                // ) : (
                // <div className='atuações-cards' onClick={() => setSucessão(true)}>
                //     {/* <img src='/Images/genealogica.png' alt='Icone Elaboração'/> */}
                //     <h4 id='h4-sucessorio'>Direito Sucessório <span>(Inventário e Partilha)</span></h4>
                //     <p>Clique no card para saber mais</p>
                // </div>
                // )
            }
        </div>
    </SwiperSlide>
    <SwiperSlide id='digital'>
        {/* <h2>Direito Digital <img src='https://img.icons8.com/fluency-systems-regular/48/null/studio-display.png' alt='Desktop'></img></h2> */}
        <h2>Direito de Família</h2>
        <div className='atuações-container'>
           {
                // implementação ? (
                // <div className='atuações-explicações-digital' onClick={() => setImplementação(false)}>
                //     <p><em>Implementação da LGPD nas empresas de todos os tipos societários (pequeno, médio e grande porte).</em></p>
                // </div>
                // ) : (
                // <div className='atuações-cards-digital'  onClick={() => setImplementação(true)}>
                //     {/* <img src='https://img.icons8.com/ios/50/null/law-book.png' alt='Icone Reclamação'/> */}
                //     <h4>Implementação</h4>
                //     <p>Clique no card para saber mais</p>
                // </div>
                // )
            }
            {
                dados ? (
                <div className='atuações-explicações-digital' onClick={() => setDados(false)}>
                    <h4>Ação de divórcio</h4>
                    <h4>Ação e execução de alimentos</h4>
                    <h4>Ações de paternidade</h4>
                    <h4>Alteração do regime de bens</h4>
                    <h4>Reconhecimento e extinção de união estável</h4>
                    <h4>Guarda, visitação e filiação</h4>
                </div>
                ) : (
                <div className='atuações-cards-digital' onClick={() => setDados(true)}>
                    {/* <img src='https://img.icons8.com/ios/50/null/gdpr-laptop.png' alt='Icone Ações'/> */}
                    <p>Clique no card para saber mais</p>
                </div>

                )
            }
        </div>
    </SwiperSlide>
    <SwiperSlide id='tributário'>
        {/* <h2>Direito Tributário <img src='https://img.icons8.com/dotty/80/null/weak-financial-growth.png' alt='folha cifrão'></img></h2> */}
        <h2>Direito de Sucessões</h2>
          <div className='atuações-container'>
            {
                preventina ? (
                <div className='atuações-explicações-digital' onClick={() => setPreventina(false)}>
                    <h4>Inventário judicial e/ou extrajudicial</h4>
                </div>
                ) : (
                <div className='atuações-cards-digital' onClick={() => setPreventina(true)}>
                    {/* <img src='https://img.icons8.com/ios/50/null/box-important--v1.png' alt='Icone Reclamação'/> */}
                    <h4>Inventário e Partilha</h4>
                    <p>Clique no card para saber mais</p>
                </div>
                )
            }
            {
                // repressiva ? (
                // <div className='atuações-explicações-digital' onClick={() => setRepressiva(false)}>
                //     <p>Processo administrativo; ações Judiciais e execuções fiscais</p>
                // </div>
                // ) : (
                // <div className='atuações-cards-digital' onClick={() => setRepressiva(true)}>
                //     <h4>Atuação repressiva</h4>
                //     <p>Clique no card para saber mais</p>
                // </div>
                // )
            }
        </div>
    </SwiperSlide>
  </Swiper>
  </div>
  )
}

export default Areas
